<template>
  <hs-modal :id="id" size="xl" bodyClass="px-sm-4 px-md-5 py-3" hide-footer>
    <hs-loading v-if="isLoading" />
    <template v-else>
      <div class="content-term" v-html="content"></div>
    </template>
  </hs-modal>
</template>

<script>
import { termService } from '@/services';
import { hsModal, hsLoading } from '@/components';
import toastHelper from '@/shared/helpers/toast';
export default {
  name: 'hsTermModal',
  props: {
    kind: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    showErrorAlert: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: true,
      content: '',
    };
  },
  components: {
    hsModal,
    hsLoading,
  },
  mounted() {
    this.getContentTerm();
  },
  watch: {
    kind(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetData();
        this.getContentTerm();
      }
    },
  },
  methods: {
    async getContentTerm() {
      if (this.kind) {
        try {
          const { data } = await termService.getTerms(this.kind);
          this.content = data[0].content;
        } catch (err) {
          this.$bvModal.hide(this.id);
          if (this.showErrorAlert) {
            toastHelper.dangerMessage(this.$t('components.term-modal.loading.error'));
          }
        }
        this.isLoading = false;
      }
    },
    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
};
</script>

<style lang="scss" scoped>
.content-term /deep/ {
  * {
    line-height: 1.3em;
  }
  h2 {
    margin: 1rem 0;
    color: #ff305c;
    font-size: 1.5rem;
  }
  h3 {
    margin: 0.8rem 0;
    font-size: 1.2rem;
  }
  h4 {
    margin-bottom: 0.625rem;
    font-size: 1rem;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
    line-height: 1.4em;
    margin-bottom: 0.625rem;
  }
  ol,
  ul {
    padding-left: 1.5em;
    margin-bottom: 1rem;
  }
  ol > li,
  ul > li {
    list-style-type: none;
    font-size: 0.9rem;
  }
  ul > li::before {
    content: '\2022';
  }
  ul[data-checked='true'],
  ul[data-checked='false'] {
    pointer-events: none;
  }
  ul[data-checked='true'] > li *,
  ul[data-checked='false'] > li * {
    pointer-events: all;
  }
  ul[data-checked='true'] > li::before,
  ul[data-checked='false'] > li::before {
    color: #777;
    cursor: pointer;
    pointer-events: all;
  }
  ul[data-checked='true'] > li::before {
    content: '\2611';
  }
  ul[data-checked='false'] > li::before {
    content: '\2610';
  }
  li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }
  li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }
  li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }
  ol li:not(.ql-direction-rtl),
  ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
  }
  ol li.ql-direction-rtl,
  ul li.ql-direction-rtl {
    padding-right: 1.5em;
  }
  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
  }
  ol li:before {
    content: counter(list-0, decimal) '. ';
  }
  ol li.ql-indent-1 {
    counter-increment: list-1;
  }
  ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
  }
  ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-2 {
    counter-increment: list-2;
  }
  ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
  }
  ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-3 {
    counter-increment: list-3;
  }
  ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
  }
  ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-4 {
    counter-increment: list-4;
  }
  ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
  }
  ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-5 {
    counter-increment: list-5;
  }
  ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
  }
  ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
  }
  ol li.ql-indent-6 {
    counter-increment: list-6;
  }
  ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
  }
  ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
  }
  ol li.ql-indent-7 {
    counter-increment: list-7;
  }
  ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
  }
  ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
  }
  ol li.ql-indent-8 {
    counter-increment: list-8;
  }
  ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
  }
  ol li.ql-indent-8 {
    counter-reset: list-9;
  }
  ol li.ql-indent-9 {
    counter-increment: list-9;
  }
  ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
  }
  .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
  }
  li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em;
  }
  .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
  }
  li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em;
  }
  .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
  }
  li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em;
  }
  .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
  }
  li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em;
  }
  .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
  }
  li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em;
  }
  .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
  }
  li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em;
  }
  .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
  }
  li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em;
  }
  .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
  }
  li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em;
  }
  .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
  }
  li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em;
  }
  .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
  }
  li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em;
  }
  .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
  }
  li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em;
  }
  .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
  }
  li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em;
  }
  .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
  }
  li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em;
  }
  .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
  }
  li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em;
  }
  .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
  }
  li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em;
  }
  .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
  }
  li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em;
  }
  .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
  }
  li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em;
  }
  .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
  }
  li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em;
  }
}
</style>
